<script>
import RouterView from "vue-router";

export default {
  components: {
    RouterView,
  },
};
</script>

<template>
  <v-app>
    <RouterView />
  </v-app>
</template>
