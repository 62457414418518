<template>
  <v-alert
    border="start"
    :icon="icon(style)"
    :color="style"
    variant="tonal"
    class="mb-2"
    closable
    ><span v-html="text"></span
  ></v-alert>
</template>

<script>
export default {
  props: ["style", "text"],
  methods: {
    icon(style) {
      return `$${style}`;
    },
  },
};
</script>
