<template>
  <v-overlay
    v-model="overlay"
    class="align-center justify-center"
    persistent
    contained
  >
    <v-progress-circular
      color="primary"
      size="64"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      overlay: true,
    };
  },
};
</script>
