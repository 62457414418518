<template>
  <v-navigation-drawer v-model="drawer">
    <v-list-item title="My Application" subtitle="Vuetify"></v-list-item>
    <v-divider></v-divider>
    <v-list-item link title="List Item 1"></v-list-item>
    <v-list-item link title="List Item 2"></v-list-item>
    <v-list-item link title="Sair" @click="logout"></v-list-item>
  </v-navigation-drawer>

  <v-app-bar>
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-app-bar-title>Olá, {{ getPrimeiroNome() }}</v-app-bar-title>

    <template #append>
      <v-btn size="small" icon :to="{ name: 'inbox' }" class="mr-1">
        <v-badge dot color="red">
          <v-icon icon="mdi mdi-bell" color="primary"></v-icon>
        </v-badge>
      </v-btn>
      <v-spacer class="mx-1"></v-spacer>
      <v-btn size="small" icon :to="{ name: 'profile' }">
        <v-avatar v-if="!avatarLoadError" size="small" class="mx-2">
          <v-img
            :src="data_usuario.avatar_url"
            @error="errorHandler(data_usuario.uid)"
            alt="Foto de perfil"
            cover
          >
            <template v-slot:placeholder>
              <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-avatar>
        <v-avatar
          v-else
          size="small"
          class="mx-2"
          icon="mdi mdi-account"
          color="error"
        ></v-avatar>
      </v-btn>
    </template>
  </v-app-bar>
  <v-main>
    <div class="mx-2 my-3">
      <Alert
        v-if="message"
        :style="message.style"
        :text="message.text"
        @click:close="this.message = null"
      />
    </div>
    <CarteirinhaComponent />
    <!-- <MenuComponent /> -->
    <Loading v-model="loading" />
  </v-main>
  <Footer />
</template>

<script setup>
import { ref } from "vue";

const drawer = ref(null);
</script>

<script>
import axios from "axios";
// import MenuComponent from "@/components/MenuComponent.vue";
import CarteirinhaComponent from "@/components/CarteirinhaComponent.vue";
import Footer from "@/components/Footer.vue";
import Loading from "@/components/Loading.vue";
import Alert from "@/components/Alert.vue";

export default {
  data: () => ({
    loading: false,
    message: null,
    drawer: null,
    data_usuario: {},
    avatarLoadError: false,
  }),
  created: function () {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.get_usuario();
  },
  components: {
    // MenuComponent,
    CarteirinhaComponent,
    Footer,
  },
  methods: {
    get_usuario() {
      if (!localStorage.getItem("usuario")) {
        this.loading = true;
        axios
          .get(process.env.VUE_APP_API_BASE_URL + "/usuario", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            localStorage.setItem("usuario", JSON.stringify(response.data));
            this.data_usuario = response.data;
          })
          .catch(() => {
            this.message = {
              style: "error",
              text: "Erro ao carregar informações do usuário.",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.data_usuario = JSON.parse(localStorage.getItem("usuario"));
      }
      this.data_usuario.avatar_url =
        process.env.VUE_APP_AVATAR_URL + `/${this.data_usuario.uid}.jpeg`;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("usuario");
      this.$router.push("/login");
    },
    getPrimeiroNome() {
      let pnome = String(this.data_usuario.nome).split(" ")[0].toLowerCase();
      return pnome.charAt(0).toUpperCase() + pnome.slice(1);
    },
    errorHandler() {
      this.avatarLoadError = true;
    },
  },
};
</script>
