<template>
  <v-footer app class="elevation-3">
    <v-row justify="center" class="text-center">
      <v-col cols="12">
        {{ new Date().getFullYear() }} • FHEMOAM - Departamento de Sistemas •
        App Hemoam v{{ appVersion }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style scoped></style>
